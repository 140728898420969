// Generated by Framer (0f540f8)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import OtherPricing from "./q5iOsQe9p";
const OtherPricingFonts = getFonts(OtherPricing);

const cycleOrder = ["CDav8QE00", "iq7RSDVVr"];

const serializationHash = "framer-6xqqI"

const variantClassNames = {CDav8QE00: "framer-v-1jiuezw", iq7RSDVVr: "framer-v-dakqsk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.05)", opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "CDav8QE00", "Variant 2": "iq7RSDVVr"}

const getProps = ({btnVisible, height, id, width, ...props}) => { return {...props, FmIV_VzUT: btnVisible ?? props.FmIV_VzUT ?? true, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CDav8QE00"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;btnVisible?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FmIV_VzUT, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CDav8QE00", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const pzLkHnzNu39sxee = activeVariantCallback(async (...args) => {
setVariant("iq7RSDVVr")
})

const pzLkHnzNu1bw95re = activeVariantCallback(async (...args) => {
setVariant("CDav8QE00")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jiuezw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CDav8QE00"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({iq7RSDVVr: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider height={101} width={componentViewport?.width || "100vw"} y={((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 351) - 0) - 212) / 2) + 0 + 0))}><motion.div className={"framer-1rsxd6j-container"} layoutDependency={layoutDependency} layoutId={"lpXmZ7Z6f-container"} whileHover={animation}><OtherPricing height={"100%"} id={"lpXmZ7Z6f"} IY4fehfVO={20} layoutId={"lpXmZ7Z6f"} pzLkHnzNu={pzLkHnzNu39sxee} S1AC65rwt style={{width: "100%"}} variant={"b2yavFr_3"} width={"100%"} {...addPropertyOverrides({iq7RSDVVr: {pzLkHnzNu: pzLkHnzNu1bw95re, variant: "uRB9wHQnY"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider><ComponentViewportProvider height={101} width={componentViewport?.width || "100vw"} y={((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 351) - 0) - 212) / 2) + 101 + 10))}><motion.div className={"framer-1btict7-container"} layoutDependency={layoutDependency} layoutId={"ADrkoBhkm-container"} whileHover={animation}><OtherPricing height={"100%"} id={"ADrkoBhkm"} IY4fehfVO={20} layoutId={"ADrkoBhkm"} pzLkHnzNu={pzLkHnzNu39sxee} S1AC65rwt={FmIV_VzUT} style={{width: "100%"}} variant={"ZR0MlIy5n"} width={"100%"} {...addPropertyOverrides({iq7RSDVVr: {pzLkHnzNu: pzLkHnzNu1bw95re, variant: "VkoaTndnU"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6xqqI.framer-160d3i3, .framer-6xqqI .framer-160d3i3 { display: block; }", ".framer-6xqqI.framer-1jiuezw { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 530px; }", ".framer-6xqqI .framer-1rsxd6j-container, .framer-6xqqI .framer-1btict7-container { flex: none; height: auto; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6xqqI.framer-1jiuezw { gap: 0px; } .framer-6xqqI.framer-1jiuezw > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-6xqqI.framer-1jiuezw > :first-child { margin-top: 0px; } .framer-6xqqI.framer-1jiuezw > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 351
 * @framerIntrinsicWidth 530
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"iq7RSDVVr":{"layout":["fixed","auto"]}}}
 * @framerVariables {"FmIV_VzUT":"btnVisible"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRnonM2CHp: React.ComponentType<Props> = withCSS(Component, css, "framer-6xqqI") as typeof Component;
export default FramerRnonM2CHp;

FramerRnonM2CHp.displayName = "Global/Pricing Options";

FramerRnonM2CHp.defaultProps = {height: 351, width: 530};

addPropertyControls(FramerRnonM2CHp, {variant: {options: ["CDav8QE00", "iq7RSDVVr"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, FmIV_VzUT: {defaultValue: true, title: "Btn Visible", type: ControlType.Boolean}} as any)

addFonts(FramerRnonM2CHp, [{explicitInter: true, fonts: []}, ...OtherPricingFonts], {supportsExplicitInterCodegen: true})